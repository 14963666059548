<template>
  <div class="stampStatistics">
    <div class="stampStatistics-title dp-f jc-sb">
      <div class="fs-20 fw-b">印章使用统计</div>
      <tags @selectEmit="getData($event.value)" />
    </div>
    <div class="content">
      <div class="mt-50">
        <count :bothpd='49' width='364px' :counts=state.counts v-loading="countLoading"
          :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner"
          :element-loading-background="config.loading.background"></count>
      </div>
      <div class="charts" v-loading="loading" :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
        <pieChart ref="piehomeChartRef"></pieChart>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,unref, reactive,onMounted } from "vue"
import count from "@/components/count/count.vue"
import pieChart from "./piehomeChart.vue"
import tags from "@/views/home/components/tool/tags.vue"
import { getXialaListdata2 } from "@/utils/server/selectdata.js"
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const piehomeChartRef=ref()
const loading = ref(false);//loading 显示
const countLoading = ref(false);//loading 显示
const state = reactive({
  counts:getXialaListdata2(['合同数','用印次数'],[0,0])
})
// 获取统计数据
const getCountData = (type) => {
  countLoading.value = true
  queryResourceTable('/initiate-contract/contract/sealCount', qs.stringify({
    type: type
  })).then(res => {
    state.counts[0].value=res.data.count
    state.counts[1].value=res.data.makeCount
    unref(piehomeChartRef).setpieChart(res.data.workTypeMap)
    countLoading.value = false;
  }).catch((err) => {
    countLoading.value = false;
  })
}
onMounted(()=>{
  getCountData(1)
})
const getData = (type) => {
  getCountData(type)
}
</script>

<style lang="scss" scoped>
.stampStatistics {
  width: 100%;
  height: 441px;
  padding: 20px 30px 0;
  background-color: #fff;

  .content {
    margin-top: 27px;
    display: flex;

    .charts {
      width: 100%;
      height: 350px;
    }
  }
}</style>
